import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import { AreaguideBaseURL } from './utils';

const PopularSearchOffice = (props) => {
    var area = props.area
    var SaleURL = '/properties-for-sale/'
    var LetURL = '/properties-to-rent/'
    var searchactionsale = 'for Sale'
    var searchactionlet = 'to Rent'
    var searcharea = props.area.toLowerCase();
    return (<React.Fragment>
    <Col md={6} lg={3}>
            <div className="footer-all-links">
                <ul>
            <li><Link className="footer_scroll_top" to={`${SaleURL}${searcharea}/`}>Property {searchactionsale} in {area}</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}${searcharea}/house/`}>Houses {searchactionsale} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${SaleURL}${searcharea}/apartment/`}>Apartments {searchactionsale} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${SaleURL}${searcharea}/maisonette/`}>Maisonette {searchactionsale} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${SaleURL}${searcharea}/bungalow/`}>Bungalow {searchactionsale} in {area}</Link></li>
</ul>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                        <div className="footer-all-links">
                        <ul>
            <li><Link className="footer_scroll_top" to={`${LetURL}${searcharea}/`}>Property {searchactionlet} in {searcharea}</Link></li>
                <li><Link className="footer_scroll_top" to={`${LetURL}${searcharea}/house/`}>Houses {searchactionlet} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${LetURL}${searcharea}/apartment/`}>Apartments {searchactionlet} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${LetURL}${searcharea}/maisonette/`}>Maisonette {searchactionlet} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${LetURL}${searcharea}/bungalow/`}>Bungalow {searchactionlet} in {area}</Link></li>
</ul>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                        <div className="footer-all-links">
                        <ul>
                        <li><Link href="/branches/">Estate Agents in Essex &amp; Kent</Link></li>
                        <li><Link href="/property-auctions/">Property Auctions in Essex &amp; Kent</Link></li>
                        <li><Link href="/about/customer-reviews/">Customer Reviews</Link></li>
                        <li><Link href="/about/latest-property-news/">Latest Property News
</Link></li>
                        </ul>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                        <div className="footer-all-links">
                        <ul>
                        <li><Link className="footer_scroll_top" to={`/property-valuation/`}>Property Valuation in Essex & Kent</Link></li>
                        <li><Link className="footer_scroll_top" to={`/about/careers/`}>Estate Agent Jobs in Essex & Kent</Link></li>
                        {/* {props.guide && props.guide.Select_Areaguide?
                        <li><Link className="footer_scroll_top" to={`${AreaguideBaseURL}/${props.guide.Select_Areaguide.URL}-area-guides`}>{props.guide.Select_Areaguide.Name} Area Guide</Link></li>                
                            : */}
                            <li><Link className="footer_scroll_top" to={`/branches/area-guides`}>Essex Area Guides</Link></li>                         
                        {/* } */}
                            <li><Link className="footer_scroll_top" to={`/marketing-your-property/`}>Market Your Property</Link></li>
                          </ul>
                        </div>
                    </Col> 
                    </React.Fragment>)

}

export default PopularSearchOffice;
