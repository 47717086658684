import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import $ from 'jquery'
import GetAreaguide from "./get-areaguide";
import GetOffice from "./get-office";
const PopularSearchDynamic = (props) => {
  //Get values from refine search
  const [ptype, setPtype] = useState(false)
  const [searchbed, setsearchbed] = useState(false)
  var searchparams = props.searchParams 
  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText = ''
  var area = 'essex-and-kent'
  var typeText = 'Property'
  var searcharea = ''
  var searchareaText = ''
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  if (searchparams.pType == 'sales') {
    searchaction = 'for Sale'
    URL = '/properties-for-sale/'
  }
  if (searchparams.pType == 'lettings') {
    searchaction = 'to Rent'
    URL = '/properties-to-rent/'
  }
  if (searchparams.type) {
    ptypeURL = searchparams.type
    typeText = capitalizeFirstLetter(searchparams.type.replace(/-/g, ' '))
  }
  if (searchparams.bedrooms) {
    bedtypeText = searchparams.bedrooms + ' Bedroom ' + typeText
    bedURL = '/' + searchparams.bedrooms + '-and-more-bedrooms'
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if (searchparams.areas && searchparams.areas  != 'undefined') {
    searcharea = searchparams.areas.toLowerCase().replace(/ /g,"-");
    searcharea = searcharea.replace(/%20/g, "-")
    searchareaText = searchparams.areas.replace(/%20/g, " ")
    searchareaText = capitalizeFirstLetter(searchareaText)
  }
  return (<React.Fragment>
    <Row>
      {searchparams.bedrooms ?
        <Col md={6} lg={3}>
          <div className="footer-all-links">
            <ul>
              <li><Link className="footer_scroll_top" to={`${URL}basildon${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Basildon</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}upminster${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Upminster</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}brentwood${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Brentwood</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}chelmsford${ptypeURL}${bedURL}`}>{bedtypeText} {searchaction} in Chelmsford</Link></li>
            </ul>
          </div>
        </Col>
        : <Col md={6} lg={3}>
          <div className="footer-all-links">
            <ul>
              <li><Link className="footer_scroll_top" to={`${URL}basildon/${ptypeURL?ptypeURL+"/":""}`}>{typeText} {searchaction} in Basildon</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}upminster/${ptypeURL?ptypeURL+"/":""}`}>{typeText} {searchaction} in Upminster</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}brentwood/${ptypeURL?ptypeURL+"/":""}`}>{typeText} {searchaction} in Brentwood</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}chelmsford/${ptypeURL?ptypeURL+"/":""}`}>{typeText} {searchaction} in Chelmsford</Link></li>
            </ul>
          </div>
        </Col>
      }
      {searcharea ?
        <Col md={6} lg={3}>
          <div className="footer-all-links">
            <ul>
              {ptype && searchbed &&
                <li><Link className="footer_scroll_top" to={`${URL}${searcharea}`}>Property {searchaction} in {searchareaText}</Link></li>
              }
              {ptype && searcharea &&
                <li><Link className="footer_scroll_top" to={`${URL}${searcharea}`}>Property {searchaction} in {searchareaText}</Link></li>
              }
              <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/house/`}>Houses {searchaction} in {searchareaText}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/apartment/`}>Apartments {searchaction} in {searchareaText}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/maisonette/`}>Maisonettes  {searchaction} in {searchareaText}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/bungalow/`}>Bungalows  {searchaction} in {searchareaText}</Link></li>
            </ul>
          </div>
        </Col> :
        <Col md={6} lg={3}>
          <div className="footer-all-links">
            <ul>
              <li><Link className="footer_scroll_top" to={`${URL}essex-and-kent/house/`}>Houses {searchaction} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}essex-and-kent/apartment/`}>Apartments {searchaction} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}essex-and-kent/maisonette/`}>Maisonettes {searchaction} in {area}</Link></li>
              <li><Link className="footer_scroll_top" to={`${URL}essex-and-kent/bungalow/`}>Bungalows {searchaction} in {area}</Link></li>

            </ul>
          </div>
        </Col>
      }
      <Col md={6} lg={3}>
        <div className="footer-all-links">
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}${ptypeURL?'/'+ptypeURL:""}/1-and-more-bedrooms/`}>1 bedroom {typeText} {searchaction} in {searchareaText}
            </Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}${ptypeURL?'/'+ptypeURL:""}/2-and-more-bedrooms/`}>2 bedroom {typeText} {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}${ptypeURL?'/'+ptypeURL:""}/3-and-more-bedrooms/`}>3 bedroom {typeText} {searchaction} in {searchareaText}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}${ptypeURL?'/'+ptypeURL:""}/4-and-more-bedrooms/`}>4 bedroom {typeText} {searchaction} in {searchareaText}</Link></li>
          </ul>
        </div>
      </Col>
      <Col md={6} lg={3}>
        <div className="footer-all-links">
          <ul>
            <li><Link className="footer_scroll_top" to={`/property-valuation/`}>Property Valuation in Essex & Kent</Link></li>
            {/* <GetAreaguide area={searcharea} />      */}
            <GetOffice area={searcharea} />     
            {/* <li><Link className="footer_scroll_top" to={`/branches/`}>Estate Agents in {searchareaText}</Link></li> */}
            <li><Link className="footer_scroll_top" to={`/branches/area-guides/`}>Essex & Kent Area Guides</Link></li>                         
            <li><Link className="footer_scroll_top" to={`/marketing-your-property/`}>Market Your Property</Link></li>
          </ul>
        </div>
      </Col>
    </Row>
  </React.Fragment>)

}

export default PopularSearchDynamic;
