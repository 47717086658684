import React from 'react';
import { Container, Row, Col, Form } from "react-bootstrap"
import { Link } from "gatsby";
import parse from 'html-react-parser';
import { useStaticQuery, graphql } from "gatsby"


function PopularSearchStatic(props) {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        globalConfig {
            Popular_Search_Static_Common
            Popular_Search_Static_Sales
            Popular_Search_Static_Rentals
        }
      }
    }
  `)
    var footer_data = data.glstrapi.globalConfig;
    var popularSearchtype = props.popularSearch?props.popularSearch:"Popular_Search_Static_Common";
    return (
        <>
            {popularSearchtype=='Popular_Search_Static_Common' ?
            parse(footer_data.Popular_Search_Static_Common)
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Sales' ?
            parse(footer_data.Popular_Search_Static_Sales)
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Rentals' ?
            parse(footer_data.Popular_Search_Static_Rentals)
            : ''
            }
        </>
    )
}

export default PopularSearchStatic
