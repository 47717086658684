import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import $ from "jquery";
import Topbar from '../../Components/Header/Topbar';
// Images
import BrandLogo from "../../images/logo.png";
import MenuBg from "../../images/menu-bg.jpg";
import SearchOverlay from "../SearchOverlay/SearchOverlay"
import "./Header.scss";
import MenuData from './MenuData';
import { useStaticQuery, graphql } from "gatsby"
import {APPIcon} from '../icons'

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

const Header = (props) => {
  
  const data = useStaticQuery(graphql`
  query  {  
    glstrapi {
      globalConfig {
        Facebook_Link
        Instagram_Link
        Twitter_Link
        Menu_Bg_Image {
          url
        }
      }
      allMenus(sort:"Sorting:asc") {
        id
        Label
        Primary_URL
        Secondary_URL
        Show_In_Burger
        New_Tab_Open
        Main_Parent {
          id
          Label
          Primary_URL
          Secondary_URL
        }
        Sub_Parent {
          id
          Label
          Primary_URL
          Secondary_URL
        }
      }
    }
  }
`)


  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [state, setState] = useState([
    {
      name: "Find a Property",
      submenu: [
        {
          name: "Mortgages",
          isActive: false,
        },
        {
          name: "Seller's guide",
          isActive: false,
        },
        {
          name: "Tenants guide",
          isActive: false,
        },
        {
          name: "Buyers guide",
          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "Value my Property",
      submenu: [
        {
          name: "Mortgages",
          isActive: false,
        },
        {
          name: "Seller's guide",
          isActive: false,
        },
        {
          name: "Tenants guide",
          isActive: false,
        },
        {
          name: "Buyers guide",
          isActive: false,
        },
      ],
      isActive: false,
    },
    
    {
      name: "Our Services",
      submenu: [
        {
          name: "Sellers",
          submenu: [
            "Mortgages ",
            "Seller's guide",
            "Tenants guide",
            "Buyers guide",
          ],
          isActive: false,
        },
        {
          name: "Landlords",
          submenu: [
            "Free Valuation",
            "Why Let Through Us",
            "Guide to Letting",
           
          ],
          isActive: false,
        },
        {
          name: "Buyers",
          submenu: [
          
            "Seller's guide",
            "Tenants guide",
            "Buyers guide",
          ],
          isActive: false,
        },
        {
          name: "Tenants",
          submenu: [
          
            "Seller's guide",
            "Tenants guide",
            "Buyers guide",
          ],
          isActive: false,
        },
        {
          name: "New Homes",
          submenu: [
            
            "Seller's guide",
            "Tenants guide",
            "Buyers guide",
          ],
          isActive: false,
        },
        {
          name: "Mortgages",
          submenu: [
          
            "Seller's guide",
            "Tenants guide",
            "Buyers guide",
          ],
          isActive: false,
        },
        {
          name: "Auctions",
          submenu: [
          
            "Seller's guide",
            "Tenants guide",
            "Buyers guide",
          ],
          isActive: false,
        }
      ],
      isActive: false,
    },
    {
      name: "About",
      submenu: [
       
        {
          name: "Seller's guide",
          isActive: false,
        },
        {
          name: "Tenants guide",
          isActive: false,
        },
        {
          name: "Buyers guide",
          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "Contact",
      submenu: [
      
        {
          name: "Seller's guide",
          isActive: false,
        },
        {
          name: "Tenants guide",
          isActive: false,
        },
        {
          name: "Buyers guide",
          isActive: false,
        },
      ],
      isActive: false,
    }
  ]);

  useEffect(() => {  
    $('.find-prop-menu').click(function(){
      $(window).scrollTop(0);
    });
    document.querySelector("body").classList.remove("overflow-hidden");
    
    window.addEventListener("scroll", () => {
      
      if(props.pageclass === "with-intro-banner") {
        setScroll(window.scrollY > 0);
      } else {
        setScroll(window.scrollY > 120);
      }

    });
  }, []);

  const showMenu = () => {
    setShow(!show);
      document.querySelector("body").classList.add("overflow-hidden");          
  };
  const handlerOpenMenu = (index) => {
    let newState = [...state];
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    let isActive = a[index].isActive;
    a[index].isActive = !isActive;
    setState(a);
  };

  const handlerOpenSubMenu = (e, index, i) => {
    e.stopPropagation();
    let newState = [...state];
    let a = newState[index].submenu.map((item, j) => {
      if (i !== j) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    newState[index].submenu = a;
    let isActive = newState[index].submenu[i].isActive;
    newState[index].submenu[i].isActive = !isActive;
    setState(newState);
  };

  const closeMenu =()=>{
    setShow(!show);
    document.querySelector("body").classList.remove("overflow-hidden");
    setState(
      state.map(menu =>{
        if(Array.isArray(menu.submenu)) {
          let p =  menu.submenu.map(sub =>  ({ ...sub,...{isActive:false}}));
          return ({...menu,...{submenu:p},...{isActive: false}})
        } else {
          return ({...menu,...{isActive:false}})
        }
      })
    ) 
  }
  var menu_data = data.glstrapi.allMenus;
  var burger_fields = data.glstrapi.globalConfig;

  // Search overlay
  function searchOverlay(e) {
    $("body")
        .addClass("modal-open")
        .append("<div class='search-modal modal-backdrop fade'></div>")
    $(".search-overlay").addClass("active-search")
  }
  // Search overlay

  return (
    <>
      {props.title === "Home" &&
        <Topbar/>
      }
      <header className={scroll ? "header--fixed header" : "header"}>
      <SearchOverlay />      
        <Container fluid>
          <Row>
            <Col>
              <Nav>
                <div className="header-wrapper">
               
                  <div className="menu-wrap">
                 <button className="hamburger" onClick={showMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                 <span> menu</span>
                 </div>
                 
                  <div className="nav-right ml-auto  d-flex">
                  <div className="d-none d-lg-flex menu-wrap">
                    {/* <Link to="/b-smart-app/" className="menu-link menu-link-first app-link">
                      <i className="icon-app-mobile"><APPIcon/></i>B-Smart App
                    </Link> */}
                    <Link to="/property-valuation/" className="menu-link menu-link-first">
                      <i className="icon-trend"></i>Free market appraisal
                    </Link>
                    <Link to="/properties-for-sale/essex-and-kent/" className="menu-link find-prop-menu">
                      <i className="icon-home"></i>Find a property
                    </Link>
                    </div>
                    <Link to={myaccountURL} className="sb-myacc">
                      <i className="icon-user"></i>
                    </Link>
                      <div className="header-btn d-md-block ">
                      <button type="button" className="btn-link"><i className="icon-user d-none"></i></button>
                      <button type="button" className="btn-link"><i className="icon-search" onClick={searchOverlay}></i></button>
                      </div>

                      <div className="logo">
                        <span className="logo-head">Balgores Property Group</span>
                      <Link to="/"> <img src={BrandLogo} alt="logo" width="68" /></Link>
                    </div>
                  </div>

                </div>
                {/* Navigation */}
                <div
                    className={
                      show
                        ? "d-flex navigation-wrap active justify-content-center"
                        : "d-flex navigation-wrap justify-content-center"
                    }
                  >
                    <a href="javascript:;" className="close-btn" onClick={closeMenu}>
                      <i className="icon-close"></i>
                    </a>
                    <div className="menu-bg">
                      <img src={burger_fields.Menu_Bg_Image.url} alt="menu" />
                    </div>
                    <Nav>
                      <div className="navigation">
                      <div className="menu-accordian">
                  {menu_data.map((item, i) => (
                    <MenuData
                      key={i}
                      index={i}
                      handlerOpenMenu={handlerOpenMenu}
                      handlerOpenSubMenu={handlerOpenSubMenu}
                      item={item}
                    />
                  ))}
                      </div>
                      <div className="social-icons">
                  <ul>
                    <li>
                      <a href={burger_fields.Twitter_Link} target="_blank" className="">
                        <i className="icon-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={burger_fields.Facebook_Link} target="_blank" className="">
                        <i className="icon-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={burger_fields.Instagram_Link} target="_blank" className="">
                        <i className="icon-insta"></i>
                      </a>
                    </li>
                    <li className="yutube-class">
                      <a href="https://www.youtube.com/channel/UCggu22BWJBJpvHcsQPjv2iQ" target="_blank" className="">
                        <i className="icon-utube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                      </div>
                 
                    </Nav>
                
                  </div>
              </Nav>
            </Col>
          </Row>
        </Container>

        {/* <div className="navigation-block">
          <nav className="nav-menu">
            <ul>
              <li>
                <Link to="#">Property Search</Link>
              </li>
              <li>
                <Link to="#">Valuations</Link>
              </li>
              <li>
                <Link to="#">Services</Link>
              </li>
              <li>
                <Link to="#">Insights</Link>
              </li>
              <li>
                <Link to="#">About</Link>
              </li>
              <li>
                <Link to="#">Contact</Link>
              </li>
            </ul>
          </nav>
        </div> */}
      </header>
    </>
  );
};

export default Header;
