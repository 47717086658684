import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Form  } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll";
import StarberryLogo from "../../images/starberry.svg";
import "./Footer.scss";
import FooterMenu from './FooterMenu';
import { useStaticQuery, graphql } from "gatsby"
import $ from "jquery"
import NewsletterForm from '../forms/newsletter-form'
import Helmet from "react-helmet"
import CookieConsent from "react-cookie-consent";
import PopularSearch from "../popular-search-static";
import PopularSearchDynamic from "../popular-search-dynamic";
import PopularSearchDetails from "../popular-search-details";
import PopularSearchOffice from "../popular-search-office-details";
const Footer = (props) => {
  const data = useStaticQuery(graphql`
  query  {  
    glstrapi {
      globalConfig {
        Facebook_Link
        Instagram_Link
        Twitter_Link
        Menu_Bg_Image {
          url
        }
      }
    }
  }
`)
  const [search, setSearch] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);


  const [state, setState] = useState([
    { 
      name: "Services",
      submenu: [
        {
          name: "Balgores Boost",
          isActive: false,
        },
        {
          name: "Mortage Advice",
          isActive: false,
        },
        {
          name: "Financial Advice",
          isActive: false,
        },
        {
          name: "Auctions",
          isActive: false,
        },
        {
          name: "Land and New Homes",
          isActive: false,
        },
      ],
      isActive: false,
    },
    {
      name: "About",
      submenu: [
        {
          name: "About Us",
          isActive: false,
        },
        {
          name: "Reviews",
          isActive: false,
        },
        {
          name: "Careers",
          isActive: false,
        },
        {
          name: "News",
          isActive: false,
        },
        {
          name: "Meet the Team",
          isActive: false,
        },
      ],
      
      isActive: false,
    },
    {
      name: "Branches",
      submenu: [
        {
          name: "Basildon",
          isActive: false,
        },
        {
          name: "Loughton",
          isActive: false,
        },
        {
          name: "Brentwood",
          isActive: false,
        },
        {
          name: "Chelmsford",
          isActive: false,
        },
        {
          name: "Dagenham",
          isActive: false,
        },
        {
          name: "Hornchurch Sales",
          isActive: false,
        },
       
        {
          name: "Gravesend",
          isActive: false,
        },
        {
          name: "Romford Sales",
          isActive: false,
        },
        {
          name: "South Woodham Ferrers",
          isActive: false,
        },
        {
          name: "Upminster",
          isActive: false,
        },
        {
          name: "Ongar",
          isActive: false,
        },
      ],
      isActive: false,
    },
    
  ]);

  const openSearch=()=>{
    setSearch(!search)
  }

  const clickHandler = (index) => {
    let newState = [...state];
    let a = newState.map((item, i) => {
      if (index !== i) {
        return { ...item, isActive: false };
      } else {
        return item;
      }
    });
    let isActive = a[index].isActive;
    a[index].isActive = !isActive;
    setState(a);
  }
    var footer_fields = data.glstrapi.globalConfig;
    var date = new Date();
    useEffect(() => {
      $(".open-popular-search").click(function(event) {
        $(".open-popular-search").toggleClass("searchopen");
        $(".popular-searchlist").stop().slideToggle();
      });        
      
      setTimeout(function(){
        if ($('.footer .cookie-popup .CookieConsent').length > 0) { 
          $('.footer').addClass('with_cookie-popup');
        } 
        $('.cookieok').click(function(event) {
          $('.acceptcookie').click();
        }); 
        $('.popular-searchlist .footer-all-links a').click(function() {
          $(window).scrollTop(0);
        });
      }, 500);
    }, [])

    useEffect(() => {
      window.addEventListener("mousemove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("keypress", () => { 
          if (renderComponent === false) {
            setRenderComponent(true)
          }
        })
      window.addEventListener("touchmove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      }) 
  }, [])
  
  
  
    
    const ClosePopup = () => {
      if (typeof window !== 'undefined') {
        $('.footer').removeClass('with_cookie-popup');
      }
    }
    return (
      
        <React.Fragment>
          {/* BUGHERD */}
          {/* <Helmet>
            <script src="https://www.bugherd.com/sidebarv2.js?apikey=e8hms14jy5yfhwxbo90wzq" type="text/javascript" />
          </Helmet> */}
          {/* BUGHERD */}
          
        {/* YOMDEL CHAT */}
        {/* <Helmet 
        script={[{ 
          type: 'text/javascript', 
          innerHTML: '(function() {var theScript = document.createElement("script");theScript.setAttribute("type", "text/javascript");theScript.setAttribute("src", "https://clients.yomdel.com/tools/chat_script.js?url=" + document.location.href);document.getElementsByTagName("body")[0].appendChild(theScript);theScript.onerror = function(){}})();'
        }]} /> */}
        {/* YOMDEL CHAT */}
          <footer className="footer">
            <Container>
            <Row className="footer-block3 popular-searches">
                <div className={search ? "popular-search-block open-footer-block" : "popular-search-block"}>
                  <div className="popular-search-head open-popular-search">
                    <span>Popular Property Searches </span>
                    <i className="icon-plus"></i>
                    <i className="icon-minus"></i>
                  </div>
                  <div className="popular-searchlist">
                  <Row className="row-block">
                    {(props.searchtype === "results") ?
                    <PopularSearchDynamic searchParams={props.searchParams} />   
                    :
                    (props.searchtype === "details") ?
                    <PopularSearchDetails ptype={props.ptype} property_building={props.property_building} searchBedroomfield={props.searchBedroomfield} property_address={props.property_address} display_address={props.display_address}/>                 
                    :
                    (props.searchtype === "officedetails") ?
                    <PopularSearchOffice area={props.area} guide={props.guide} />                 
                    :
                    <PopularSearch popularSearch={props.popularSearch} />
                    }
                    {/* <Col md={6} lg={3}>
                        <div className="footer-all-links">
                          <ul>
                            <li><Link to="#">Properties for sale in London</Link></li>
                            <li><Link to="#">Properties for sale in Notting Hill</Link></li>
                            <li><Link to="#">Properties for sale in Bayswater</Link></li>
                            <li><Link to="#">Properties for sale in Kensington</Link></li>
                          </ul>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                        <div className="footer-all-links">
                        <ul>
                            <li><Link to="#">Properties for sale in London</Link></li>
                            <li><Link to="#">Properties for sale in Notting Hill</Link></li>
                            <li><Link to="#">Properties for sale in Bayswater</Link></li>
                            <li><Link to="#">Properties for sale in Kensington</Link></li>
                          </ul>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                        <div className="footer-all-links">
                        <ul>
                            <li><Link to="#">Estate Agents in London</Link></li>
                            <li><Link to="#">Notting Hill Property Valuation</Link></li>
                            <li><Link to="#">About Strettons</Link></li>
                            <li><Link to="#">Kensington Area Guides</Link></li>
                          </ul>
                        </div>
                    </Col>
                    <Col md={6} lg={3}>
                        <div className="footer-all-links">
                        <ul>
                            <li><Link to="#">London Property Management</Link></li>
                            <li><Link to="#">Meet the Team</Link></li>
                            <li><Link to="#">Googel Reviews</Link></li>
                            <li><Link to="#">Proeprty Services</Link></li>
                          </ul>
                        </div>
                    </Col> */}
                    </Row>
                    </div>
                  </div>
                  
                  
              </Row>
            
           
             <div className="footer-link-block">
            <div className="footer-left">
            <Row className="footer-block1 footer-quick-links">
                <FooterMenu />
              </Row>
            </div>
          
            
             <div className="footer-right">
             <Row className="footer-block2 social-icons-terms-conditions">
                <div className="terms-and-conditions">
                 <span className="heading-text">Newsletter</span>
                 <p>Stay up to date with latest news and featured properties</p>
                 {renderComponent &&
                  <NewsletterForm/>
                 }
                </div>
              </Row>
             </div>
             </div>
              <Row>
                <Col md="100">
                <div className="social-icons">
                  <ul>
                    <li>
                      <a href={footer_fields.Twitter_Link} target="_blank" className="">
                        <i className="icon-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={footer_fields.Facebook_Link} target="_blank" className="">
                        <i className="icon-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={footer_fields.Instagram_Link} target="_blank" className="">
                        <i className="icon-insta"></i>
                      </a>
                    </li>
                    <li className="yutube-class">
                      <a href="https://www.youtube.com/channel/UCggu22BWJBJpvHcsQPjv2iQ" target="_blank" className="">
                        <i className="icon-utube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                </Col>
              </Row>

              <Row>
                <Col md="100">
                <div className="footer-link">
                  <ul>
                    <li>
                      <Link to="/terms-and-conditions/">
                      Terms & Conditions 
                      </Link>
                    </li>
                    <li>
                      <Link to="/cookies/">
                      Cookies      
                      </Link>
                    </li>
                    <li>
                      <Link to="/sitemap/">
                      Sitemap
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy/">
                      Privacy Policy
                      </Link>
                    </li>
                  </ul>
                  <span>© {date.getFullYear()} Balgores</span>
                  <p className="ft-siteby">Site by <a href="https://starberry.tv/" target="_blank"><img src={StarberryLogo} alt="Starberry Logo"/></a></p>
                </div>
                </Col>
              </Row>
              </Container>
              <section className="cookie-popup">
              <CookieConsent
                    location="bottom"
                    buttonText="Accept Cookies"
                    cookieName="CookiePolicy"
                    buttonClasses="acceptcookie"
                    overlay={false}
                    // acceptOnScrollPercentage={1}
                    onAccept={()=> ClosePopup()}
                >
                    <div className="topbar-block">

                        <p>
                        We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to='/cookies'>Cookie Policy</Link>. <a href="javascript:void(0);" class="cookieok">Accept Cookies</a>
                        </p>
                        <i className="icon-close-dark"></i>
                    </div>
                </CookieConsent>
                </section>
          </footer>  
        </React.Fragment>
    )
}

export default Footer;