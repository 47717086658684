import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { OfficeBaseURL } from './utils';
import { Link } from "gatsby"
import {GetPopularSearchOffice} from '../queries/common_use_query';
const GetOffice = (props) => {
  const {loading:loading, error:error, data:data} = GetPopularSearchOffice(props.area);
  //console.log('aaaaaa',data)
  return (          
    <React.Fragment>        
        {data && data.ourOffices.slice(0, 1).map((office,index) => {
            return<>
                <li><Link className="footer_scroll_top" to={`${OfficeBaseURL}${office.URL}/`}>Estate Agents in {office.Name}</Link></li>                
            </>
        })}  
        {data && data.ourOffices.length === 0 &&
         
            <li><Link className="footer_scroll_top" to={`/branches/`}>Estate Agents in Essex</Link></li>                         
        }
    </React.Fragment>
    )
};
export default GetOffice;
