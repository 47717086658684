import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import $ from 'jquery'
import GetAreaguide from "./get-areaguide";
import GetOffice from "./get-office";
const PopularSearchDetails = (props) => {
  //Get values from refine search
  const [ptype, setPtype] = useState(false)
  const [searchbed, setsearchbed] = useState(false)


  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText = ''
  var area = 'essex-and-kent'
  var typeText = 'Property'
  var searcharea = ''
  var searchareaText = ''
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  if (props.ptype == 'sales') {
    searchaction = 'for Sale'
    URL = '/properties-for-sale/'
  }
  if (props.ptype == 'lettings') {
    searchaction = 'to Rent'
    URL = '/properties-to-rent/'
  }
  // if(props.property_building) {
  //   ptypeURL   =  props.property_building
  //   typeText   = capitalizeFirstLetter(props.property_building.replace(/-/g, ' '))
  // }
  if (props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield + ' Bedroom ' + typeText
    bedURL = '/' + props.searchBedroomfield + '-and-more-bedrooms'
  }
  if (props.property_address) {
    area = props.property_address.area
    area = capitalizeFirstLetter(area.replace(/%20/g, " "))
    searchareaText = props.property_address.area.replace(/%20/g, " ")
    searcharea = props.property_address.area.toLowerCase().replace(/%20/g, "-");
    searcharea = searcharea.replace(/ /g,"-");
  }
  return (<React.Fragment>
    <Row>

      <Col md={6} lg={3}>
        <div className="footer-all-links">
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}basildon${ptypeURL}/`}>{typeText} {searchaction} in Basildon</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}upminster${ptypeURL}/`}>{typeText} {searchaction} in Upminster</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}brentwood${ptypeURL}/`}>{typeText} {searchaction} in Brentwood</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}chelmsford${ptypeURL}/`}>{typeText} {searchaction} in Chelmsford</Link></li>
          </ul>
        </div>
      </Col>

      <Col md={6} lg={3}>
        <div className="footer-all-links">
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/house/`}>Houses {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/apartment/`}>Apartments {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/maisonette/`}>Maisonettes {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/bungalow`}>Bungalows {searchaction} in {area}</Link></li>

          </ul>
        </div>
      </Col>
      <Col md={6} lg={3}>
        <div className="footer-all-links">
          <ul>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/1-and-more-bedrooms`}>1 bedroom Property {searchaction} in {area}
            </Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/2-and-more-bedrooms`}>2 bedroom Property {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/3-and-more-bedrooms`}>3 bedroom Property {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}${searcharea}/4-and-more-bedrooms`}>4 bedroom Property {searchaction} in {area}</Link></li>
          </ul>
        </div>
      </Col>

      <Col md={6} lg={3}>
        <div className="footer-all-links">
          <ul>
            <li><Link className="footer_scroll_top" to={`/property-valuation/`}>Property Valuation in Essex & Kent</Link></li>
            {/* <GetAreaguide area={searcharea} />      */}
            <GetOffice area={searcharea} />  
            <li><Link className="footer_scroll_top" to={`/branches/area-guides/`}>Essex & Kent Area Guides</Link></li>                         
            <li><Link className="footer_scroll_top" to={`/marketing-your-property`}>Market Your Property</Link></li>
          </ul>
        </div>
      </Col>
    </Row>



  </React.Fragment>)

}

export default PopularSearchDetails;
